import React, { useCallback } from 'react';

import { ReactComponent as AppleIcon } from '../../icons/appleIcon.svg';
import { ReactComponent as AndroidIcon } from '../../icons/androidIcon.svg';

import styles from './index.module.scss';
import classNames from 'classnames';
import { isAndroid } from 'react-device-detect';

import { androidUrl, appleUrl } from '../../constants';

export const AndroidButton = () => {
  const handleRedirect = useCallback(() => {
    window.location.href = androidUrl;
  }, []);

  return (
    <button
      className={classNames(styles.button, styles.android, 'downloadLink')}
      data-label={'google'}
      onClick={handleRedirect}
    >
      <AndroidIcon /> BAIXAR PARA ANDROID
    </button>
  );
};

export const IosButton = () => {
  const handleRedirect = useCallback(() => {
    window.location.href = appleUrl;
  }, []);

  return (
    <button
      className={classNames(styles.button, styles.ios, 'downloadLink')}
      data-label={'ios'}
      onClick={handleRedirect}
    >
      <AppleIcon /> BAIXAR PARA IOS
    </button>
  );
};

export const GenericDownloadButton = () => {
  const url = isAndroid ? androidUrl : appleUrl;

  const handleRedirect = useCallback(() => {
    window.location.href = url;
  }, []);

  return (
    <button
      className={classNames(styles.button, 'downloadLink')}
      data-label={url.includes('apple') ? 'ios' : 'google'}
      onClick={handleRedirect}
    >
      BAIXAR O MULTI
    </button>
  );
};

export const StoreButton = () => {
  const handleRedirect = useCallback((url) => {
    window.location.href = url;
  }, []);

  return (
    <button
      className={classNames(styles.button, styles.storeButton, 'storeOwnerLink')}
      onClick={() => handleRedirect('https://paginas.meumulti.com.br/lojista/')}
    >
      SOU LOJISTA
    </button>
  );
};
