import React, { useContext } from 'react';
import { StoreButton } from '../../components/button';

import styles from './index.module.scss';

import MyContext from '../../context';

const NinthModule = () => {
  const { fourthRef } = useContext(MyContext);

  return (
    <div className={styles.container} ref={fourthRef}>
      <h1 className={styles.title}>Você é lojista? Descubra o que o Multi faz por você.</h1>
      <p className={styles.description}>
        Clique no botão e <strong>saiba mais sobre as possibilidades para você</strong>.
      </p>
      <StoreButton />
    </div>
  );
};

export default NinthModule;
