import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';

import { ReactComponent as Arrow } from '../../icons/arrow.svg';

import notas from './images/notas-illustration.png';
import cupons from './images/cupons-illustration.png';
import llv from './images/llv-illustration.png';

import notasMobile from './images/notas-illustration-mobile.png';
import cuponsMobile from './images/cupons-illustration-mobile.png';
import llvMobile from './images/llv-illustration-mobile.png';

import styles from './index.module.scss';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import MyContext from '../../context';

const ThirdModule = () => {
  const [state, setState] = useState(1);
  const intervalId = useRef();

  const intervalFunc = () => setState((state) => (state === 3 ? 1 : state + 1));

  const handleButtonClick = useCallback(
    (number) => {
      clearInterval(intervalId.current);
      setState(number);
      intervalId.current = setInterval(intervalFunc, 7000);
    },
    [intervalId],
  );

  useEffect(() => {
    intervalId.current = setInterval(intervalFunc, 7000);
    return () => clearInterval(intervalId.current);
  }, []);

  const { secondRef } = useContext(MyContext);

  return (
    <>
      <div className={styles.moreInfo}>
        <p>E TEM MAIS NO MULTI</p>
        <Arrow />
        <Arrow />
      </div>
      <div className={styles.container} ref={secondRef}>
        <div className={styles.textBox}>
          <h1>
            <strong>Vantagens exclusivas,</strong> promoções e ofertas para você.
          </h1>
          <p>
            Participe de todas as promoções e não perca ofertas nos produtos,{' '}
            <strong>além de contar com cupons especiais para aproveitar de casa ou no shopping.</strong>
          </p>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonBox}>
              <button
                className={classNames(styles.cuponsButton, state !== 1 && styles.notSelected)}
                onClick={() => handleButtonClick(1)}
              />
              {state === 1 && <p className={styles.cuponsDescription}>CUPONS PRESENCIAIS</p>}
            </div>

            <div className={styles.buttonBox}>
              <button
                className={classNames(styles.llvButton, state !== 2 && styles.notSelected)}
                onClick={() => handleButtonClick(2)}
              />
              {state === 2 && <p className={styles.llvDescription}>LIQUIDAÇÃO DO LÁPIS VERMELHO</p>}
            </div>
            <div className={classNames(styles.buttonBox, state === 3 && styles.selectedBox)}>
              <button
                className={classNames(styles.notesButton, state !== 3 && styles.notSelected)}
                onClick={() => handleButtonClick(3)}
              />
              {state === 3 && <p className={styles.notesDescription}>Promoções com troca de notas</p>}
            </div>
          </div>
        </div>
        {isMobile ? (
          <div className={styles.imgBox}>
            <img src={notasMobile} className={state !== 3 ? styles.transparent : undefined} />
            <img src={llvMobile} className={state !== 2 ? styles.transparent : undefined} />
            <img src={cuponsMobile} className={state !== 1 ? styles.transparent : undefined} />
          </div>
        ) : (
          <div className={styles.imgBox}>
            <img src={notas} className={state !== 3 ? styles.transparent : undefined} />
            <img src={llv} className={state !== 2 ? styles.transparent : undefined} />
            <img src={cupons} className={state !== 1 ? styles.transparent : undefined} />
          </div>
        )}
      </div>
    </>
  );
};

export default ThirdModule;
