import React from 'react';
import ReactPlayer from 'react-player/lazy';

import styles from './index.module.scss';

import MultiVCLogo from './images/multiVCLogo.png';
import MVCPlaceholder from './images/mockup-mvc.png';

import myVideo from './videos/webm-multivoce.webm';

import { isDesktop, isIOS, isSafari } from 'react-device-detect';

const FourthModule = () => {
  if (isDesktop) {
    return (
      <div className={styles.container}>
        {isSafari ? (
          <img src={MVCPlaceholder} className={styles.placeholderImage} />
        ) : (
          <ReactPlayer url={myVideo} muted playing loop playsInline controls={false} className={styles.reactPlayer} />
        )}
        <div className={styles.textBox}>
          <img src={MultiVCLogo} className={styles.multiVcLogo} />
          <h1>
            Transforme suas compras em pontos <strong>e troque por benefícios</strong>
          </h1>
          <p>
            <strong>O MultiVocê é o programa de fidelidade dos shoppings.</strong> Funciona assim: você cadastra suas
            notas fiscais no aplicativo e acumula pontos para ter benefícios exclusivos!
          </p>
          <legend>Consulte se o programa está disponível no seu shopping</legend>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.textBox}>
          <img src={MultiVCLogo} />
          <h1>
            Transforme suas compras em pontos <strong>e troque por benefícios</strong>.
          </h1>
          <p>
            <strong>O MultiVocê é o programa de fidelidade dos shoppings.</strong> Você cadastra suas notas fiscais no
            aplicativo e acumula pontos para ter benefícios exclusivos!
          </p>
          <legend>Consulte se o programa está disponível no seu shopping</legend>
        </div>
        {isSafari || isIOS ? (
          <img src={MVCPlaceholder} className={styles.placeholderImage} />
        ) : (
          <ReactPlayer url={myVideo} muted playing loop playsInline controls={false} className={styles.reactPlayer} />
        )}
      </div>
    );
  }
};

export default FourthModule;
