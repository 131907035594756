import React from 'react';
import Step1 from './images/delete-account-step-1.webp';
import Step2 from './images/delete-account-step-2.webp';
import Step3 from './images/delete-account-step-3.webp';

import styles from './index.module.scss';

const DeleteAccountModule = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1>Quero excluir minha conta</h1>
        <div className={styles.textBox}>
          <p>
            O processo de exclusão da conta não é automático e poderá levar até 30 dias para ser concluído. A qualquer
            momento durante esse período, você perderá acesso ao app ou notará os impactos listados acima.
            <br />A Multiplan poderá reter, temporariamente, certos dados após a exclusão da conta, conforme exigido
            e/ou permitido por Lei. Para proteger sua privacidade, o processo de exclusão de conta segue as regras e os
            princípios da Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018.
          </p>
        </div>

        <h2>Como fazer?</h2>
        <div className={styles.stepsContainer}>
          <div className={styles.step}>
            <img src={Step1} alt="Etapa 1" className={styles.stepImage} />
            <p>1. Acesse o Aplicativo Multi e escolha o menu que fica na parte inferior direita da tela</p>
          </div>
          <div className={styles.step}>
            <img src={Step2} alt="Etapa 2" className={styles.stepImage} />
            <p>2. Escolha a opção fale conosco</p>
          </div>
          <div className={styles.step}>
            <img src={Step3} alt="Etapa 3" className={styles.stepImage} />
            <p>3. Escolha a opção cancelar minha conta</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModule;
