import React, { useCallback } from 'react';

import styles from './index.module.scss';

import iosDownloadButton from '../header/images//iosDownloadButton.png';
import googleDownloadButton from '../header/images/googleDownloadButton.png';
import classNames from 'classnames';
import { androidUrl, appleUrl } from '../../constants';

const StickyFooter = ({ bottom }) => {
  const handleRedirect = useCallback((url) => {
    window.location.href = url;
  }, []);

  return (
    <div className={classNames(styles.bottomFooter, bottom && styles.nonScrollableFooter)}>
      <img
        src={iosDownloadButton}
        className={classNames(styles.iosDownloadButton, 'downloadLink')}
        data-label={'ios'}
        onClick={() => handleRedirect(appleUrl)}
      />
      <img
        src={googleDownloadButton}
        className={classNames(styles.googleDownloadButton, 'downloadLink')}
        data-label={'google'}
        onClick={() => handleRedirect(androidUrl)}
      />
    </div>
  );
};

export default StickyFooter;
