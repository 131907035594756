import React from 'react';

import Header from '../../modules/header';

import Footer from '../../modules/footer';
import PrivacyPolicyModule from '../../modules/PrivacyPolicyModule';

const PrivacyPolicy = () => (
  <div>
    <Header />
    <PrivacyPolicyModule />
    <Footer />
  </div>
);

export default PrivacyPolicy;
