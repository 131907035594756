import React, { useContext } from 'react';

import styles from './index.module.scss';

import { ReactComponent as Stores } from './images/ic-store.svg';
import { ReactComponent as Foods } from './images/ic-foods.svg';
import { ReactComponent as Notes } from './images/ic-shopping-notes.svg';
import { ReactComponent as Coupon } from './images/ic-cupom.svg';
import { ReactComponent as Llv } from './images/ic-llv.svg';
import { ReactComponent as Parking } from './images/ic-parking.svg';
import { ReactComponent as Cinema } from './images/ic-cinema.svg';
import { ReactComponent as News } from './images/ic-notice.svg';

import FeatureBox from './components/featureBox/featureBox';

import MyContext from '../../context';
import { isDesktop } from 'react-device-detect';

const SecondModule = () => {
  const features = [
    {
      icon: <Stores />,
      title: 'Fale com as lojas',
      description: 'Entre em contato com o lojista e <b>combine sua compra</b>',
      color: '#008258',
    },
    {
      icon: <Foods />,
      title: 'Conheça nossos restaurantes',
      description: '<b>Restaurantes exclusivos do shopping</b> também estão no app',
      color: '#D67C2D',
    },
    {
      icon: <Notes />,
      title: 'Cadastre suas notas fiscais',
      description: 'Participe de promoções <b>cadastrando as notas pelo app</b>',
      color: '#8364BE',
    },
    {
      icon: <Coupon />,
      title: 'Ative cupons de desconto',
      description: 'Vantagens e ofertas exclusivas para <b>aproveitar no shopping</b>',
      color: '#045AC9',
    },
    {
      icon: <Llv />,
      title: 'Liquidação Lápis Vermelho',
      description: 'Aproveite a liquidação mais <b>tradicional dos shoppings</b>',
      color: '#C1362F',
    },
    {
      icon: <Parking />,
      title: 'Pague o estacionamento',
      description: 'Pague seu estacionamento com <b>praticidade e segurança</b>',
      color: '#1A7F89',
    },
    {
      icon: <Cinema />,
      title: 'Aproveite o cinema',
      description: '<b>Veja o que está em cartaz no cinema</b> e garanta seu ingresso',
      color: '#787575',
    },
    {
      icon: <News />,
      title: 'Veja todas as dicas e novidades',
      description: '<b>Fique informado</b> sobre tudo que acontece no shopping',
      color: '#AD337C',
    },
  ];
  const { firstRef } = useContext(MyContext);
  if (isDesktop) {
    return (
      <div className={styles.container} ref={firstRef}>
        <h1 className={styles.title} id={'oMulti'}>
          o Multi é um <i>superapp</i>
        </h1>
        <p className={styles.description}>
          São <strong>várias possibilidades</strong> para transformar suas experiências no shopping e{' '}
          <strong>onde você estiver</strong>.
        </p>
        <div className={styles.boxContainer}>
          <div className={styles.featureBoxRow}>
            {features.slice(0, 4).map((feature, index) => (
              <FeatureBox key={index} {...feature} />
            ))}
          </div>
          <div className={styles.featureBoxRow}>
            {features.slice(4, 8).map((feature, index) => (
              <FeatureBox key={index} {...feature} />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container} ref={firstRef}>
      <div className={styles.textBox}>
        <h1 className={styles.title} id={'oMulti'}>
          o Multi é um <i>superapp</i>.
        </h1>
        <p className={styles.description}>
          São <strong>várias possibilidades</strong> para transformar suas experiências no shopping e{' '}
          <strong>onde você estiver</strong>.
        </p>
      </div>
      <div className={styles.boxContainer}>
        <div className={styles.boxContainer}>
          {features.map((feature, index) => (
            <FeatureBox key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecondModule;
