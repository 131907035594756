import React from 'react';

import styles from './index.module.scss';

const PrivacyPolicyModule = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1>POLÍTICA DE PRIVACIDADE DA MULTIPLAN</h1>
        <p>Atualizada pela última vez em de novembro de 2020</p>

        <p>
          A Multiplan Empreendimentos Imobiliários S.A. (“Multiplan” ou “nós”) está comprometida com a sua privacidade.
          Por isso, esta Política de Privacidade (“Política” ou “Política de Privacidade”) tem como objetivo informar
          como tratamos os dados pessoais no âmbito da prestação dos nossos serviços relacionados ao aplicativo Multi
          (“Multi”). Nossos esforços visam garantir que os dados pessoais fornecidos ou coletados somente sejam
          utilizados de acordo com a presente Política e com a legislação de privacidade e proteção de dados aplicável.
        </p>

        <p>
          Os dados pessoais que coletamos dependem do contexto das suas interações com a Multiplan. Para facilitar a sua
          compreensão, explicamos nesta Política como tratamos os dados de usuários do Multi (“Usuário” ou “você”).
        </p>

        <p>
          Esta Política pode ser acessada em formato digital na seção “Política de Privacidade” disponibilizada no
          Multi. Lembramos, ainda, que você pode contatar a Multiplan, por meio do e-mail{' '}
          <a href="mailto:atendimento@meumulti.com.br">atendimento@meumulti.com.br</a>, para que respondamos suas
          dúvidas sobre nossa Política de Privacidade.
        </p>

        <p>
          <strong>
            CASO VOCÊ NÃO CONCORDE COM QUAISQUER DISPOSIÇÕES DESTA POLÍTICA DE PRIVACIDADE, POR FAVOR NÃO UTILIZE NOSSO
            APLICATIVO E NÃO REALIZE QUALQUER OPERAÇÃO POR MEIO DOS NOSSOS SISTEMAS.
          </strong>
        </p>

        <h2>1. Quem é o controlador dos seus dados pessoais?</h2>
        <p>
          A Multiplan é a controladora dos dados pessoais para as finalidades de tratamento de dados pessoais descritas
          nesta Política. Para fins da legislação de proteção de dados aplicável, controlador é a quem compete as
          decisões referentes ao tratamento de dados pessoais.
        </p>

        <h2>2. Quais dados pessoais coletamos?</h2>
        <p>
          De forma geral, quando um Usuário de alguma forma utiliza ou interage com o Multi, nós coletamos seus dados
          pessoais, que podem incluir:
        </p>
        <ul>
          <li>Nome completo, CPF, e-mail, endereços, números de telefone e celular;</li>
          <li>Data de nascimento e gênero;</li>
          <li>Dados do seu cartão de crédito;</li>
          <li>
            Dados de transações realizadas no Multi (por exemplo, quando você compra um produto pela funcionalidade
            Marketplace ou paga estacionamento);
          </li>
          <li>
            Dados de compras coletados durante a sua participação no programa de fidelidade MultiVocê ou em alguma
            promoção comercial (por exemplo, dados extraídos da leitura de notas fiscais de compra cadastradas pelo
            Usuário ou dados relacionados a sua participação, como lojas envolvidas, produtos transacionados e preços);
          </li>
          <li>
            Dados de acesso e navegação (quando você interage por recurso ou ferramenta do Multi ou clica em qualquer
            publicidade, oferta ou promoção divulgado a você dentro do Multi, ainda que sejam ofertas de parceiros da
            Multiplan ou de lojistas, as informações relacionadas a tais atividades, publicidades, ofertas e promoções
            poderão ser mantidas em nosso sistema. O Multi também poderá registrar informações, tais como hora, tipo de
            navegador, linguagem do navegador, endereço de Protocolo de Internet (IP), localização, data e hora das suas
            conexões).
          </li>
        </ul>
        <p>
          Como regra, a Multiplan não coleta dados sensíveis dos seus Usuários. No entanto, nos casos em que a coleta de
          dados dessa natureza ocorrer, como nos dados de saúde ou transações envolvendo itens específicos, a Multiplan
          adotará todas as medidas necessárias para garantir a legalidade do tratamento e te informará sobre o referido
          tratamento (por meio de atualização desta Política ou de comunicação direta com você).
        </p>

        <h2>3. Para que utilizamos seus dados pessoais?</h2>
        <p>Nós coletamos os dados pessoais do Usuário para as seguintes finalidades:</p>
        <ul>
          <li>Realizar o cadastro no aplicativo Multi;</li>
          <li>Armazenar o registro de compras e operacionalizá-las;</li>
          <li>Efetuar o pagamento dos pedidos e de estacionamento;</li>
          <li>
            Registrar o comportamento dos Usuários ao utilizar o aplicativo Multi e aprimorar ou facilitar os serviços
            oferecidos pelo Multi ou pelas sociedades integrantes do grupo Multiplan;
          </li>
          <li>Prestar serviço de atendimento ao Usuário;</li>
          <li>
            Ajudar o Usuário e os parceiros comerciais a entrarem em contato direto entre si, por meio do Multi, após o
            Usuário manifestar sua intenção de interagir com determinado parceiro comercial;
          </li>
          <li>
            Enviar informativos sobre promoções em geral e/ou ofertas de serviços e produtos das Promotoras via e-mail,
            notificações, aplicativo, SMS e/ou WhatsApp;
          </li>
          <li>
            Traçar perfil de compra do Usuário para oferecer uma melhor experiência no seu relacionamento com o Multi,
            disponibilizando ofertas e conteúdo personalizados;
          </li>
          <li>
            Permitir que os parceiros comerciais do Multi realizem a prestação de serviços diretamente aos Usuários;
          </li>
          <li>
            Desenvolver estudos internos sobre os interesses, comportamentos e demografia dos Usuários para compreender
            melhor suas preferências pessoais para aprimorar e customizar a sua experiência no uso do Multi;
          </li>
          <li>
            Aprimorar nossas iniciativas comerciais e promocionais e analisar as páginas visitadas, buscas realizadas
            pelos Usuários para melhorar nossa oferta de conteúdos e produtos e personalizá-los ao seu interesse;
          </li>
          <li>
            Contatar o Usuário diretamente ou por meio de parceiros comerciais da Multiplan, afiliados ou lojistas, para
            comunicar sobre alterações das ferramentas e funcionalidades do Multi, realizar pesquisas de satisfação, bem
            como informar sobre lançamento de novas ferramentas, funcionalidades, ofertas, sorteios ou promoções;
          </li>
          <li>
            Melhorar a segurança e a confiabilidade dos nossos serviços, incluindo estudos, análises e medidas contra
            fraude, abusos, riscos de segurança e problemas técnicos que possam prejudicar o Multi e seus Usuários;
          </li>
          <li>
            Classificar e agrupar informações de Usuários, assim como consolidar dados estatísticos a respeito desses,
            inclusive para definir a composição da base de Usuários do Multi e o volume de tráfego em nossos servidores.
          </li>
        </ul>

        <p>
          Também coletamos dados pessoais de Usuários no âmbito de promoções comerciais e do programa de fidelidade
          MultiVocê, além do disposto acima, conforme segue:
        </p>

        <p>
          Nas <strong>promoções comerciais</strong>, os dados pessoais também são tratados para as seguintes
          finalidades:
        </p>
        <ul>
          <li>Executar a promoção;</li>
          <li>Controlar a distribuição dos cupons e números de sorte de participação;</li>
          <li>Formar banco de dados visando analisar as participações de Usuários na promoção;</li>
          <li>Analisar as notas fiscais para a participação na promoção comercial;</li>
          <li>
            Prestar contas à Secretaria de Avaliação de Políticas Públicas, Planejamento, Energia e Loteria (“SECAP”) ou
            outros órgãos de controle que se façam necessários;
          </li>
          <li>Enviar ao Usuário informações sobre sua participação e/ou contemplação na promoção;</li>
          <li>Divulgar o nome do Usuário que for contemplado pela promoção.</li>
        </ul>

        <p>
          No <strong>Programa de fidelidade MultiVocê</strong>, os dados pessoais também são tratados para as seguintes
          finalidades:
        </p>
        <ul>
          <li>Realizar o cadastro e migrar os Usuários para o MultiVocê;</li>
          <li>Lançar os pontos para o resgate de benefícios;</li>
          <li>Identificar os Usuários beneficiados por promoção ou experiência resgatada;</li>
          <li>Analisar as notas fiscais para a participação no programa de fidelidade MultiVocê;</li>
          <li>Integrar o cadastro e os pontos dos Usuários de toda rede Multiplan;</li>
          <li>Divulgar o programa MultiVocê;</li>
          <li>Realizar auditorias nas pontuações de Usuários.</li>
        </ul>

        <p>
          Para atingir algumas das finalidades acima, o tratamento dos seus dados poderá ser baseado no seu
          consentimento. Quando isso ocorrer, garantiremos que esse consentimento seja livre, informado e inequívoco em
          relação à finalidade para a qual estiver sendo coletado por nós.
        </p>

        <p>
          Além disso, solicitaremos sua autorização antes de usar informações para outros fins que não os definidos
          nesta Política de Privacidade.
        </p>

        <h2>4. Tecnologias Utilizadas</h2>
        <p>
          Além das informações que você voluntariamente nos envia, utilizamos certas tecnologias como “cookies” e “pixel
          tags” para coletar suas informações, conforme descritas abaixo:
        </p>

        <h3>Cookies e tags:</h3>
        <p>
          A Multiplan poderá utilizar instruções eletrônicas, conhecidas como “cookies” e “tags” que serão enviadas ao
          browser e armazenadas no seu celular.
        </p>

        <p>
          Alguns cookies são necessários para o funcionamento do Multi. Esses cookies geralmente são definidos em
          resposta a ações feitas por você, tais como definir preferências de privacidade, fazer login ou preencher
          formulários.
        </p>

        <p>
          Outra finalidade dos cookies e tags é coletar informações sobre como você usa o Multi. Os cookies de
          performance nos ajudam, por exemplo, a identificar áreas especialmente populares do nosso aplicativo. Dessa
          forma, podemos adaptar o conteúdo de nosso aplicativo e de comunicações mais especificamente às suas
          necessidades e, assim, melhorar a experiência do usuário e eventualmente facilitar sua navegação com as
          características, preferências e qualidade das informações veiculadas à sua navegação. Esses cookies e tags são
          usados para enviar informações publicitárias e promocionais relevantes para você, por exemplo com base nas
          páginas do aplicativo que você visitou, nos limites autorizados pela legislação.
        </p>

        <p>
          Informamos que a Multiplan atualmente não utiliza uma solução técnica que nos permita responder plenamente aos
          sinais de “não rastrear” de seu navegador. Ao desabilitar todos os cookies nas configurações de seu navegador,
          é possível que certas seções ou recursos do Multi não funcionem ou ocasionem demasiada demora para carregar os
          conteúdos, pois seu navegador pode nos impedir de definir cookies necessários.
        </p>

        <h3>Pixel Tags:</h3>
        <p>
          Por sua vez, os pixel tags (clear gifs) servem para ajudar a gerenciar e aperfeiçoar nossos serviços,
          sistemas, publicidade e anúncios online e demais ferramentas. Pixel tags possibilitam o reconhecimento de um
          cookie de um navegador quando este visita o site no qual o pixel tag está localizado, e a registrar qual
          publicidade de banner leva o usuário a determinado site.
        </p>

        <h2>5. Websites de terceiros e ofertas e publicidades veiculadas no Multi</h2>
        <p>
          Websites de terceiros, anúncios e links relacionados a anúncios de publicidade de terceiros eventualmente
          acessíveis a partir do Multi estão sob a responsabilidade dos respectivos terceiros. A Multiplan não será
          responsável pelos pedidos e/ou fornecimento de dados pessoais em websites de terceiros. Recomendamos que você
          consulte as respectivas políticas de privacidade de tais websites para se informar adequadamente a respeito do
          uso de seus dados pessoais nessas ocasiões.
        </p>

        <h2>6. Com quem compartilhamos seus dados pessoais?</h2>
        <p>
          Nós poderemos compartilhar os dados pessoais coletados com outras empresas e entidades administradas pela
          Multiplan (incluindo consórcios, condomínios, shoppings e empreendimentos sob sua administração direta ou
          indireta), com terceiros e parceiros de negócio, que sejam relevantes para fins de viabilizar o funcionamento
          do Multi e dos serviços relacionados a ele e para atingir as finalidades descritas nesta Política. Referido
          compartilhamento ocorre com base nos seguintes critérios e para as finalidades descritas abaixo.
        </p>

        <ul>
          <li>
            <strong>Empresas do grupo da Multiplan:</strong> As empresas e entidades administradas pela Multiplan
            (incluindo consórcios, condomínios, shoppings e empreendimentos sob sua administração direta ou indireta)
            podem compartilhar entre si os dados pessoais coletados a partir da prestação dos Serviços a fim de operar,
            executar, aprimorar, entender, personalizar, dar suporte, anunciar nossos serviços e prevenir fraudes.
          </li>
          <li>
            <strong>Prestadores de serviço terceirizados:</strong> Trabalhamos com prestadores de serviço terceirizados
            para nos ajudar a realizar atividades como, por exemplo, validar a compra do serviço de tokenização para
            pagamentos digitais (de produtos e estacionamento) e para auxiliar a operacionalidade do serviço de delivery
            das promoções e de banco de dados. Quando compartilhamos dados com prestadores de serviço terceirizados,
            exigimos que eles utilizem seus dados de acordo com nossas instruções e termos ou mediante seu consentimento
            expresso, quando aplicável.
          </li>
          <li>
            <strong>Parceiros de negócio:</strong> Fornecemos informações pessoais para nossos parceiros, incluindo as
            entidades representantes dos lojistas dos shopping centers administrados pela Multiplan, caso Você
            expressamente decida interagir com eles, contratar serviços ou comprar produtos disponibilizados por meio
            deles no Multi. As informações serão fornecidas na medida do necessário para a execução das atividades de
            cada parceiro.
          </li>
          <li>
            <strong>Por motivos legais:</strong> Caso seja solicitado por uma ordem expedida por autoridade competente
            no cumprimento de suas atribuições legais, caso exista uma obrigação legal ou regulatória, ou em caso de
            violações ou suspeita de violações desta Política de Privacidade, dos Termos de Uso ou da legislação
            aplicável, poderá ser requerida à Multiplan a disponibilização dos seus dados pessoais. A Multiplan, porém,
            se compromete a revelar as informações limitando-se ao mínimo necessário para atingir as finalidades
            exigidas.
          </li>
          <li>
            <strong>Defesa de direitos:</strong> A Multiplan se reserva o direito de comunicar informação sobre seus
            Usuários a outros Usuários, lojistas ou terceiros, quando houver motivos suficientes para considerar que a
            atividade de um Usuário seja potencialmente ilícita ou prejudicial, nos termos da legislação aplicável.
          </li>
          <li>
            <strong>Novos negócios:</strong> As suas informações pessoais também poderão ser transferidas no caso de
            fusão, aquisição ou venda de ativos da Multiplan. Caso o processamento das informações após estes eventos
            seja alterado, a Multiplan notificará Você por meio do e-mail informado por você em seu cadastro.
          </li>
          <li>
            <strong>Realização de Estudos e Análises:</strong> Relatórios estatísticos sobre o acesso, utilização do
            Multi e tendências de mercado, que não revelem a identidade dos Usuários, poderão também ser compartilhados
            com afiliados, subcontratados ou parceiros comerciais da Multiplan. Essas informações serão transferidas de
            modo anonimizado, de tal modo que não faremos qualquer associação entre esses dados e você.
          </li>
          <li>
            <strong>Com a sua autorização:</strong> Em outros casos não previstos acima, havendo o objetivo de
            compartilhamento dos dados pessoais e informações, enviaremos você uma notificação com informações a
            respeito de tal compartilhamento para solicitar o seu consentimento para finalidade determinada.
          </li>
        </ul>

        <h2>7. Transferências de seus dados pessoais para fora do Brasil.</h2>
        <p>
          Excepcionalmente, a Multiplan poderá transferir alguns de seus dados pessoais a parceiros ou prestadores de
          serviços localizados no exterior, incluindo prestadores de serviços de tecnologia em nuvem. Quando os dados
          pessoais forem transferidos para fora do Brasil, a Multiplan tomará as medidas apropriadas para garantir a
          proteção adequada dos dados pessoais em conformidade com os requisitos da legislação aplicável de proteção de
          dados, incluindo por meio da celebração de contratos apropriados de transferência de dados com terceiros,
          quando necessário.
        </p>

        <h2>8. Por quanto tempo armazenamos seus dados pessoais?</h2>
        <p>
          Armazenamos e mantemos suas informações: pelo tempo exigido por lei; até o término do tratamento de dados
          pessoais, conforme mencionado abaixo; pelo tempo necessário a preservar o legítimo interesse da Multiplan
          (como, por exemplo, durante prazos prescricionais aplicáveis ou cumprimento de obrigação legal ou
          regulatória).
        </p>

        <p>O término do tratamento de dados pessoais ocorrerá quando for verificado:</p>
        <ul>
          <li>
            Que a finalidade para qual os dados foram coletados foi alcançada, e que os dados pessoais coletados
            deixaram de ser necessários ou pertinentes ao alcance da finalidade específica almejada;
          </li>
          <li>
            Uma manifestação do Titular nesse sentido, quando do término da relação entre a Multiplan e o Usuário;
          </li>
          <li>Determinação legal.</li>
        </ul>

        <p>
          Nesses casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela
          legislação aplicável ou pela presente Política de Privacidade, os dados pessoais serão eliminados. Mesmo em
          caso de solicitação de exclusão dos dados pessoais ou de revogação do consentimento, informações poderão ser
          mantidas com base em outras finalidades legais.
        </p>

        <h2>9. Quais são seus direitos em relação aos dados pessoais tratados?</h2>
        <p>Você possui direitos no que se refere aos seus dados pessoais, dentre eles:</p>
        <ul>
          <li>
            <strong>Confirmação da existência de tratamento de dados pessoais:</strong> Mediante requisição do Usuário a
            Multiplan confirmará a existência de tratamento de dados pessoais, nos termos da legislação aplicável;
          </li>
          <li>
            <strong>Acesso aos dados pessoais:</strong> O Usuário pode requisitar acesso aos seus dados pessoais
            tratados e que sejam armazenados pela Multiplan;
          </li>
          <li>
            <strong>Correção de dados incompletos, inexatos ou desatualizados:</strong> O Usuário pode e deve, a
            qualquer momento, corrigir e atualizar os seus dados pessoais, sendo responsável pelos dados informados a
            nós;
          </li>
          <li>
            <strong>Informações sobre uso compartilhado de dados:</strong> O Usuário poderá ter acesso a informações
            adicionais sobre o eventual compartilhamento de seus dados pessoais;
          </li>
          <li>
            <strong>Objeção ao tratamento de dados:</strong> O Usuário poderá manifestar sua vontade em não mais ter
            seus dados tratados pela Multiplan a qualquer momento, mediante manifestação gratuita e facilitada, caso
            entenda que o tratamento dos dados é desnecessário, excessivo ou viola alguma disposição da legislação de
            proteção de dados aplicável. Em relação aos comunicados de marketing, o Usuário também pode deixar de
            recebê-los, solicitando o cancelamento de recebimento. Os tratamentos realizados anteriormente à oposição
            são ratificados. A objeção não implicará automaticamente o término do tratamento de dados pessoais que sejam
            mantidos pelas Multiplan com base em outros fundamentos legais;
          </li>
          <li>
            <strong>Peticionar perante a ANPD:</strong> O Usuário tem o direito de peticionar perante a Autoridade
            Nacional de Proteção de Dados em relação ao tratamento dos seus dados pessoais.
          </li>
        </ul>

        <h2>10. Como tratamos seus dados e garantimos sua segurança?</h2>
        <p>
          Os dados pessoais serão processados exclusivamente de acordo com os termos descritos nesta Política de
          Privacidade e de acordo com os princípios de transparência, necessidade, minimização e legalidade previstos na
          legislação de proteção de dados e privacidade aplicáveis.{' '}
        </p>
        <p>
          Os dados pessoais podem ser processados de forma impressa ou em formato eletrônico automático e através de
          correios, e-mail, telefone e qualquer outro canal eletrônico.
        </p>
        <p>
          A Multiplan utiliza medidas técnicas e organizacionais apropriadas para proteger os dados pessoais contra
          tratamento desautorizado ou ilegal e contra perda acidental, destruição ou danos das informações. Os dados
          pessoais são armazenados de maneira segura em equipamentos protegidos. Apenas um número limitado de pessoas
          terá acesso a tais equipamentos e apenas indivíduos com motivos legítimos terão acesso a seus dados pessoais.
        </p>

        <h2>11. Contato do Encarregado</h2>
        <p>
          Se você tiver qualquer dúvida em relação a esta Política de Privacidade ou a respeito da forma como a
          Multiplan trata seus dados pessoais, basta entrar em contato com o Encarregado por meio do e-mail{' '}
          <a href="mailto:atendimento@meumulti.com.br">atendimento@meumulti.com.br</a> ou do Fale Conosco localizado no
          menu do aplicativo Multi.{' '}
        </p>

        <h2>12. Alterações nesta Política de Privacidade</h2>
        <p>
          A Multiplan reserva-se o direito de alterar esta Política de Privacidade a qualquer momento, mediante
          publicação da versão atualizada no Multi. Em caso de alterações relevantes na Política de Privacidade, você
          receberá um aviso a esse respeito.{' '}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyModule;
