import React, { useCallback, useContext, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import styles from './index.module.scss';

import MultiLogo from './images/multi.png';
import iosDownloadButton from './images/iosDownloadButton.png';
import googleDownloadButton from './images/googleDownloadButton.png';

import { ReactComponent as Menu } from './images/menu.svg';
import { ReactComponent as CloseMenu } from './images/close.svg';

import MyContext from '../../context';
import classNames from 'classnames';
import { androidUrl, appleUrl } from '../../constants';

const Header = () => {
  const scrollToRef = (ref) => window.scrollTo(0, isMobile ? ref.current.offsetTop - 75 : ref.current.offsetTop);
  const [isOpen, setIsOpen] = useState();

  const handleRedirect = useCallback((url) => {
    window.location.href = url;
  }, []);

  const { firstRef } = useContext(MyContext);
  const { secondRef } = useContext(MyContext);
  const { thirdRef } = useContext(MyContext);
  const { fourthRef } = useContext(MyContext);

  const executeScroll = (ref) => {
    isMobile && setIsOpen(false);
    scrollToRef(ref);
  };
  const useOnScreen = (ref, rootMargin = '0px') => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        {
          rootMargin,
        },
      );

      const currentElement = ref?.current;

      if (currentElement) {
        observer.observe(currentElement);
      }

      return () => {
        observer.unobserve(currentElement);
      };
    }, [ref, rootMargin]);

    return isVisible;
  };

  const firstOnScreen = useOnScreen(firstRef, '0px');
  const secondOnScreen = useOnScreen(secondRef, '0px');
  const thirdOnScreen = useOnScreen(thirdRef, '0px');
  const fourthOnScreen = useOnScreen(fourthRef, '0px');

  if (isMobile) {
    return (
      <div className={classNames(styles.headerContainer, isOpen && styles.headerOpen)}>
        {isOpen ? <CloseMenu onClick={() => setIsOpen(!isOpen)} /> : <Menu onClick={() => setIsOpen(!isOpen)} />}

        <img src={MultiLogo} className={styles.multiLogo} onClick={() => window.scrollTo(0, 0)} />

        {isOpen && (
          <div className={styles.linkBox}>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => executeScroll(firstRef)}
                className={classNames(firstOnScreen ? styles.selected : undefined, 'menuLink')}
                data-label={'oMulti'}
              >
                O Multi
              </button>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => executeScroll(secondRef)}
                className={classNames(secondOnScreen && !firstOnScreen ? styles.selected : undefined, 'menuLink')}
                data-label={'benefits'}
              >
                Vantagens
              </button>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => executeScroll(thirdRef)}
                className={classNames(thirdOnScreen ? styles.selected : undefined, 'menuLink')}
                data-label={'shoppings'}
              >
                Shoppings
              </button>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => executeScroll(fourthRef)}
                className={classNames(fourthOnScreen ? styles.selected : undefined, 'menuLink')}
                data-label={'store'}
              >
                Sou lojista
              </button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.headerContainer}>
        <div>
          <img src={MultiLogo} className={styles.multiLogo} onClick={() => window.scrollTo(0, 0)} />
        </div>
        <div className={styles.rightContainer}>
          <button
            onClick={() => executeScroll(firstRef)}
            className={classNames(firstOnScreen && !secondOnScreen ? styles.selected : undefined, 'menuLink')}
            data-label={'oMulti'}
          >
            O Multi
          </button>
          <button
            onClick={() => executeScroll(secondRef)}
            className={classNames(secondOnScreen && !firstOnScreen ? styles.selected : undefined, 'menuLink')}
            data-label={'benefits'}
          >
            Vantagens
          </button>
          <button
            onClick={() => executeScroll(thirdRef)}
            className={classNames(thirdOnScreen ? styles.selected : undefined, 'menuLink')}
            data-label={'shoppings'}
          >
            Shoppings
          </button>
          <button
            onClick={() => executeScroll(fourthRef)}
            className={classNames(fourthOnScreen ? styles.selected : undefined, 'menuLink')}
            data-label={'store'}
          >
            Sou lojista
          </button>
          <img
            src={iosDownloadButton}
            className={classNames(styles.iosDownloadButton, 'downloadLink')}
            data-label={'ios'}
            onClick={() => handleRedirect(appleUrl)}
          />
          <img
            src={googleDownloadButton}
            className={classNames(styles.googleDownloadButton, 'downloadLink')}
            data-label={'google'}
            onClick={() => handleRedirect(androidUrl)}
          />
        </div>
      </div>
    );
  }
};

export default Header;
