import React from 'react';

import Header from '../../modules/header';

import Footer from '../../modules/footer';
import DeleteAccountModule from '../../modules/deleteAccountModule';

const DeleteAccount = () => (
  <div>
    <Header />
    <DeleteAccountModule />
    <Footer />
  </div>
);

export default DeleteAccount;
