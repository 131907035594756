import React from 'react';

import Header from '../../modules/header';

import FirstModule from '../../modules/firstModule';
import SecondModule from '../../modules/secondModule';
import ThirdModule from '../../modules/thirdModule';
import FourthModule from '../../modules/fourthModule';
import FifthModule from '../../modules/fifthModule';
import SixthModule from '../../modules/sixthModule';
import SeventhModule from '../../modules/seventhModule';
import EighthModule from '../../modules/eighthModule';
import NinthModule from '../../modules/ninthModule';
import StickyFooter from '../../modules/stickyFooter';
import Footer from '../../modules/footer';
import { isMobile } from 'react-device-detect';

const LandingPage = ({ bottom }) => (
  <div>
    <Header />
    <FirstModule />
    <SecondModule />
    <ThirdModule />
    <FourthModule />
    <FifthModule />
    <SixthModule />
    <SeventhModule />
    <EighthModule />
    <NinthModule />
    <Footer />
    {isMobile && <StickyFooter bottom={bottom} />}
  </div>
);

export default LandingPage;
