import React from 'react';
import { isDesktop, isSafari, isIOS } from 'react-device-detect';
import ReactPlayer from 'react-player/lazy';

import { AndroidButton, GenericDownloadButton, IosButton } from '../../components/button';

import QRCode from './images/QR_code_Multi.png';
import ShowcaseImage from './images/showcaseThumbnail.png';

import myVideo from './video/showcase.webm';

import styles from './index.module.scss';

const FirstModule = () => {
  if (isDesktop) {
    return (
      <>
        <div className={styles.containerBackground} />
        <div className={styles.container}>
          <div className={styles.textBox}>
            <h1>O seu shopping sempre com você.</h1>
            <p>
              <strong>Com o Multi, toda a praticidade e confiança que você já conhece no shopping,</strong> agora também
              vai com você para onde quiser.
            </p>
            <AndroidButton />
            <IosButton />
          </div>
          {isSafari ? (
            <img src={ShowcaseImage} className={styles.showcaseImage} />
          ) : (
            <ReactPlayer
              url={myVideo}
              muted
              playing
              loop
              playsInline
              playsinline
              controls={false}
              config={{
                file: { forceVideo: true, attributes: { playsInline: true } },
              }}
            />
          )}
          <div className={styles.qrBox}>
            <p className={styles.qrDescription}>Aponte seu celular para o código e baixe o Multi</p>
            <img src={QRCode} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.textBox}>
            <h1>O seu shopping sempre com você</h1>
            <p>
              <strong>Com o Multi, toda a praticidade e confiança que você já conhece no shopping,</strong> agora também
              vai com você para onde quiser
            </p>
            <GenericDownloadButton />
          </div>
          {isSafari || isIOS ? (
            <img src={ShowcaseImage} className={styles.showcaseImage} />
          ) : (
            <ReactPlayer url={myVideo} muted playing loop playsInline controls={false} />
          )}
        </div>
      </>
    );
  }
};

export default FirstModule;
