import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import LandingPage from './screen/landingPage';
import DeleteAccount from './screen/delete-account';
import MyContext from './context';

import '@fontsource/work-sans';

import './App.scss';
import PrivacyPolicy from './screen/privacy-policy';

const App = () => {
  const [bottom, setBottom] = useState();
  const handleScroll = (offset) => {
    const limit =
      Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      ) - window.innerHeight;

    const bottom = limit === offset;
    if (bottom) setBottom(true);
    else setBottom(false);
  };

  useEffect(() => {
    window.onscroll = () => {
      handleScroll(window.pageYOffset);
    };
    if (isMobile) document.body.style.marginBottom = '75px';
  }, [isMobile]);

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);

  return (
    <MyContext.Provider value={{ firstRef, secondRef, thirdRef, fourthRef }}>
      <div onScroll={handleScroll} className={classNames(isMobile && 'withScrollableFooter')}>
        <Routes>
          <Route path="/" element={<LandingPage bottom={bottom} />} />
          <Route path="excluir-minha-conta" element={<DeleteAccount />} />
          <Route path="politica-de-privacidade" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </MyContext.Provider>
  );
};

export default App;
