import React, { useContext, Fragment } from 'react';

import MultiLogo from './images/logo-multiplan.png';

import { ReactComponent as BarraShopping } from './images/BarraShopping_cor-01.svg';
import { ReactComponent as BarraShoppingSul } from './images/BarraShoppingSul_cor-01.svg';
import { ReactComponent as BHShopping } from './images/BH_Shopping_cor-01.svg';
import { ReactComponent as DiamondMall } from './images/DiamondMall_cor-01.svg';
import { ReactComponent as JundiaiShopping } from './images/JundiaiShopping_cor-01.svg';
import { ReactComponent as MorumbiShopping } from './images/MorumbiShopping_cor-01.svg';
import { ReactComponent as ParkShopping } from './images/ParkShopping_cor-01.svg';
import { ReactComponent as ParkShoppingBarigui } from './images/ParkShoppingBarigui_cor-01.svg';
import { ReactComponent as ParkShoppingCampoGrande } from './images/ParkShoppingCampoGrande_cor-01.svg';
import { ReactComponent as ParkShoppingCanoas } from './images/ParkShoppingCanoas_cor-01.svg';
import { ReactComponent as ParkShoppingSaCaetano } from './images/ParkShoppingSaCaetano_cor-01.svg';
import { ReactComponent as PatioSavassi } from './images/PatioSavassi_cor-01.svg';
import { ReactComponent as RibeiraoShopping } from './images/RibeiraoShopping_cor-01.svg';
import { ReactComponent as ShoppingAnaliaFranco } from './images/ShoppingAnaliaFranco_cor-01.svg';
import { ReactComponent as ShoppingSantaUrsula } from './images/ShoppingSantaUrsula_cor-01.svg';
import { ReactComponent as ShoppingVilaOlimpia } from './images/ShoppingVilaOlimpia_cor-01.svg';
import { ReactComponent as VillageMall } from './images/VillageMall_cor-01.svg';
import { ReactComponent as ParkJacarepagua } from './images/ParkJacarepagua.svg';

import styles from './index.module.scss';
import { isMobile } from 'react-device-detect';

import MyContext from '../../context';

const SeventhModule = () => {
  const shoppingsLogos = [
    BarraShopping,
    BarraShoppingSul,
    BHShopping,
    DiamondMall,
    JundiaiShopping,
    MorumbiShopping,
    ParkShopping,
    ParkShoppingBarigui,
    VillageMall,
    ParkShoppingCanoas,
    ParkShoppingSaCaetano,
    PatioSavassi,
    RibeiraoShopping,
    ShoppingAnaliaFranco,
    ShoppingSantaUrsula,
    ShoppingVilaOlimpia,
    ParkShoppingCampoGrande,
    ParkJacarepagua,
  ];

  const stylesArray = [
    { width: `102px`, height: `24px` },
    { width: `121px`, height: `29px` },
    { width: `90px`, height: `31px` },
    { width: `89px`, height: `31px` },
    { width: `106px`, height: `30px` },
    { width: `122px`, height: `31px` },
    { width: `97px`, height: `31px` },
    { width: `137px`, height: `31px` },
    { width: `78px`, height: `31px` },
    { width: `140px`, height: `30px` },
    { width: `162px`, height: `30px` },
    { width: `92px`, height: `30px` },
    { width: `123px`, height: `30px` },
    { width: `144px`, height: `30px` },
    { width: `139px`, height: `29px` },
    { width: `133px`, height: `25px` },
    { width: `165px`, height: `31px` },
    { width: `105px`, height: `25px` },
  ];

  const { thirdRef } = useContext(MyContext);

  return (
    <div className={styles.container} ref={thirdRef}>
      <h1 className={styles.title}>
        Os seus <strong>shoppings do coração</strong> estão aqui.
      </h1>
      <p className={styles.description}>
        <strong>O Multi é o superapp dos shoppings da Multiplan, líder nacional no ramo de shoppings centers.</strong> A
        qualidade e confiança que você já conhece, agora pelo app.
      </p>
      <img src={MultiLogo} className={styles.multiLogo} />
      <div className={styles.shoppingBox}>
        {shoppingsLogos.map((Shopping, index) => (
          <Fragment key={index}>
            <Shopping className={styles.shoppingLogo} style={isMobile ? stylesArray[index] : {}} />
            {isMobile && (index + 1) % 2 !== 0 && index !== 17 && <div className={styles.divider} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default SeventhModule;
