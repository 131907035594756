import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';

const FeatureBox = ({ icon, color, title, description }) => (
  <div className={styles.box}>
    <div className={styles.boxTitle}>
      {icon}
      <p style={{ color }}>{title}</p>
    </div>
    <div className={styles.boxDescription} style={{ backgroundColor: color }}>
      <p>{ReactHtmlParser(description)}</p>
    </div>
  </div>
);

export default FeatureBox;
