import React from 'react';

import styles from './index.module.scss';

import ShopperThumbnail from './images/img-compra-direta.png';

const SixthModule = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textBox}>
        <h1 className={styles.title}>
          Entre em contato <strong>com o lojista!</strong>
        </h1>
        <p className={styles.description}>
          <strong>Converse</strong> por telefone ou WhatsApp, tire dúvidas sobre produtos, promoções, tamanhos, cores{' '}
          <strong>e combine sua compra</strong>.
        </p>
      </div>
      <img src={ShopperThumbnail} className={styles.placeholderImage} />
    </div>
  );
};

export default SixthModule;
