import React from 'react';

import styles from './index.module.scss';

import categoryFoods from './images/categorias-foods.png';
import categoryGoods from './images/categorias-goods.png';
import categoryFoodsMobile from './images/categorias-foods-mobile.png';
import categoryGoodsMobile from './images/categorias-goods-mobile.png';

import { isDesktop } from 'react-device-detect';
const FifthModule = () => {
  if (isDesktop) {
    return (
      <div className={styles.container}>
        <div className={styles.descriptionContainer}>
          <h1 className={styles.title}>
            Todas as informações sobre suas lojas e restaurantes favoritos
            <span className={styles.subtitle}> em um só lugar!</span>
          </h1>
          <p className={styles.description}>
            Confira a <strong>localização no shopping, as informações de contato</strong> e ainda fique por dentro das{' '}
            <strong>promoções e dos cupons disponíveis.</strong>
          </p>
        </div>
        <div className={styles.foodsContainer}>
          <img src={categoryFoods} />
        </div>
        <div className={styles.goodsContainer}>
          <img src={categoryGoods} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.descriptionContainer}>
          <h1 className={styles.title}>
            Todas as informações sobre suas lojas e restaurantes favoritos
            <span className={styles.subtitle}> em um só lugar!</span>
          </h1>

          <p className={styles.description}>
            Confira a <strong>localização no shopping, as informações de contato</strong> e ainda fique por dentro das{' '}
            <strong>promoções e dos cupons disponíveis.</strong>
          </p>
        </div>
        <div className={styles.foodsContainer}>
          <img src={categoryFoodsMobile} />
        </div>
        <div className={styles.goodsContainer}>
          <img src={categoryGoodsMobile} />
        </div>
      </div>
    );
  }
};

export default FifthModule;
