import React, { useCallback } from 'react';

import styles from './index.module.scss';

import { ReactComponent as Facebook } from '../../icons/Facebook.svg';
import { ReactComponent as Instagram } from '../../icons/Instagram.svg';

const Footer = () => {
  const handleRedirect = useCallback((url) => {
    window.open(url, '_blank').focus();
  }, []);

  return (
    <div className={styles.footerContainer}>
      <p>
        Administrado por{' '}
        <strong className={'multiplanLink'} onClick={() => handleRedirect('https://www.multiplan.com.br/')}>
          Multiplan
        </strong>
      </p>
      <div>
        <Facebook
          className={'socialLink facebookLink'}
          data-label={'facebook'}
          onClick={() => handleRedirect('https://www.facebook.com/meumulti/')}
        />
        <Instagram
          className={'socialLink instagramLink'}
          data-label={'instagram'}
          onClick={() => handleRedirect('https://www.instagram.com/meumulti/')}
        />
      </div>
    </div>
  );
};

export default Footer;
