import React from 'react';

import { AndroidButton, GenericDownloadButton, IosButton } from '../../components/button';

import { isDesktop } from 'react-device-detect';

import qrCode from './images/qrcode-multi.png';
import multiPhone from './images/celularMao.3fbc86f9.png';
import styles from './index.module.scss';

const EighthModule = () => {
  if (isDesktop) {
    return (
      <>
        <div className={styles.containerBackground} />
        <div className={styles.container}>
          <div className={styles.textBox}>
            <h1>
              Comece a aproveitar o <strong>melhor do seu shopping favorito</strong> com o Multi.
            </h1>
            <div className={styles.buttonsBox}>
              <img src={qrCode} className={styles.qrCodeImage} />
              <div>
                <AndroidButton />
                <IosButton />
              </div>
            </div>
          </div>
          <img src={multiPhone} className={styles.multiPhoneImage} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.containerBackground} />
        <div className={styles.container}>
          <div className={styles.textBox}>
            <h1>
              Comece a aproveitar o <strong>melhor do seu shopping favorito</strong> com o Multi
            </h1>
            <GenericDownloadButton />
          </div>
          <img src={multiPhone} className={styles.multiPhoneImage} />
        </div>
      </>
    );
  }
};

export default EighthModule;
